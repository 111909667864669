import React from 'react';
import { connect } from 'react-redux';

import { setGuess, setScore } from './logic/reducers';
import ValueSlider from './ValueSelector';

const getFeedbackMessage = (score) => {
    const absScore = Math.abs(score);

    if (absScore < 5) {
        return 'Spot on!';
    } else if (absScore < 20) {
        return 'Great!';
    } else if (absScore < 30) {
        return `Not bad, just a bit ${score > 0 ? 'dark' : 'light'}.`;
    } else if (absScore < 40) {
        return `Pushing your luck...`;
    } else if (absScore < 100) {
        return '*sad trombone*';
    } else if (absScore < 200) {
        return 'Hmm, not so much :(';
    }

    return 'Ok, now you\'re just messing with me.';
};

const AnswerSelector = (props) => {
    const {
        target, 
        guess, 
        setGuess,
        score, 
        setScore,
    } = props;

    const checkAnswer = () => {
        // placeholder
        const difference = target.grayscale - guess;
        setScore(difference);
    };

    if (!target) {
        return (
            <div className="answer-area">
                <h2>Choose a target</h2>
                Click anywhere on the image to select a target area.
            </div>
        );
    }

    let scoreContent = guess != null ? (
        <button
            className="btn"
            onClick={checkAnswer}
        >
            Check Match
        </button>
    ) : null;

    if (score != null) {
        scoreContent = (
            <React.Fragment>
                <h3>{getFeedbackMessage(score)}</h3>
                <button 
                    className="btn"
                    onClick={() => setGuess(null)}
                > 
                    Try again
                </button>
            </React.Fragment>
        );
    }

    return (
        <div className="answer-area">
            <h2>Match the value!</h2>
            <p>
                Try to guess the value in the center of the target.
                <br/><br/>
                Use Show/Hide Swatch if there's too much variation inside the target
                <br/>
                or click the image again to choose a new target.
            </p>
            <ValueSlider 
                showComparison={score != null}
                target={target}
                guess={guess}
                setGuess={setGuess}
            />            
            {scoreContent}
        </div>
    )
}

const mapStateToProps = state => ({
    guess: state.guess,
    score: state.score,
    target: state.target,
});

const mapDispatchToProps = {
    setGuess,
    setScore,
};

export default connect(
    mapStateToProps, 
    mapDispatchToProps,
)(AnswerSelector);