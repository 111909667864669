
// Given a number (representing grayscale) or object (representing rgb components)
// returns a string for use in CSS.
export const toCssColor = (color) => {
    if (typeof color === 'number') {
        // Grayscale is represented with a single number. 
        return `rgb(${color}, ${color}, ${color})`; 
    } else {
        return `rgb(${color.r}, ${color.g}, ${color.b})`;
    }
};