import classNames from 'classnames';
import React from 'react'; 

import { toCssColor } from './helpers';

const MAX_VALUE = 765;

const ValueSelector = (props) => {
    const {
        showComparison,
        target,
        guess, 
        setGuess,
    } = props;

    // TODO: throttle? 
    const updateGuess = evt => {
        const singleValue = Math.round((MAX_VALUE - evt.target.value) / 3); 

        setGuess(singleValue);
    };

    let displayedFeedback = (
        <div className="value-slider__values">
            <span className="value-slider__no-selection-message">No value selected</span>
        </div>
    );
    
    if (guess != null) {
        displayedFeedback = (
            <div className={classNames('value-slider__swatches', {
                'value-slider__swatches--results': showComparison,
            })}>
                <div className="value-slider__swatch-wrapper">
                    <div 
                        className="value-slider__swatch value-slider__current-guess-swatch"
                        style={{backgroundColor: `rgb(${guess}, ${guess}, ${guess})`}}
                    >
                    </div>
                    <div className="value-slider__current-guess-label">
                        Your guess
                    </div> 
                </div>
                {showComparison &&
                    <div className="value-slider__swatch-wrapper">
                        <div 
                            className="value-slider__swatch value-slider__answer-swatch"
                            style={{backgroundColor: toCssColor(target.grayscale)}}
                        >
                        </div>
                        <div className="value-slider__answer-label">
                            Target value
                        </div> 
                    </div>
                }
            </div>
        );
    }

    return (
        <div className="value-selector">
            <div className="value-slider"> 
                <input 
                    className={classNames('value-slider__range-slider', {
                        'value-slider__range-slider--no-selection': guess == null,
                    })}
                    type="range" 
                    min="0"
                    max={MAX_VALUE}
                    onChange={updateGuess}
                    disabled={showComparison}
                >
                </input>
                {showComparison && target &&
                    <div 
                        className="value-slider__correct-value"
                        style={{
                            right: `calc(${target.grayscale * 3 / MAX_VALUE} * (100% - 24px))`,
                            // backgroundColor: toCssColor(target.grayscale),
                        }}
                    />
                }
            </div>
            {displayedFeedback}
        </div>
    )
}

export default ValueSelector;