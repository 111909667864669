
// TODO: separate into photo state and game state & reducers? 
const INITIAL_STATE = {
    guess: null,
};

const ACTION_TYPES = {
    SET_GUESS: 'setGuess',
    SET_SCORE: 'setScore',
    SET_TARGET: 'setTarget',
    READY_NEW_ROUND: 'readyNewRound', 
};

// ACTION CREATORS
export const setTarget = (newTarget) => ({
    type: ACTION_TYPES.SET_TARGET,
    value: newTarget,
});

export const setGuess = (newGuess) => ({
    type: ACTION_TYPES.SET_GUESS,
    value: newGuess,
});

export const setScore = (newScore) => ({
    type: ACTION_TYPES.SET_SCORE,
    value: newScore,
});

export const readyNewRound = () => ({
    type: ACTION_TYPES.READY_NEW_ROUND,
});

// REDUCER
// Probably only need one reducer for this app...
const rootReducer = (state=INITIAL_STATE, action) => {
    switch(action.type) {
        case ACTION_TYPES.SET_TARGET: {
            return {
                ...state, 
                target: action.value,
            }
        }
        case ACTION_TYPES.SET_GUESS: {
            return {
                ...state, 
                guess: action.value,
                score: null,
            };
        }
        case ACTION_TYPES.SET_SCORE: {
            return {
                ...state, 
                score: action.value,
            };
        }
        case ACTION_TYPES.READY_NEW_ROUND: {
            return {
                ...state, 
                guess: null, 
                target: null,
                score: null,
            };
        }
        default: {
            return state;
        }
    }
}

export default rootReducer; 
