import classNames from 'classnames';
import React from 'react'; 
import { connect } from 'react-redux';

import { toCssColor } from './helpers';
import { setTarget } from './logic/reducers';

const TARGET_MARKER_RADIUS = 10;
const SAMPLE_RADIUS = 2;

// TODO: Swap to using canvas directly and loading image BTS.
const canvas = document.createElement('canvas');

const getTargetColor = (imgEl, x, y) => {
    // TODO: Set up canvas on image load instead of each time clicked.
    canvas.height = imgEl.height;
    canvas.width = imgEl.width;

    const context = canvas.getContext('2d');
    context.drawImage(imgEl, 0, 0, imgEl.width, imgEl.height);

    let imageData;
    try {
        const startX = Math.max(0, x - SAMPLE_RADIUS);
        const startY = Math.max(0, y - SAMPLE_RADIUS);
        imageData = context.getImageData(
            startX, 
            startY, 
            Math.min(2 * SAMPLE_RADIUS + 1, imgEl.width - startX),
            Math.min(2 * SAMPLE_RADIUS + 1, imgEl.height - startY),
        );

        // TO REMOVE - debugging purposes only
        // const canvas2 = document.querySelector('.testCanvas');
        // const context2 = canvas2.getContext('2d'); 
        // context2.putImageData(imageData, 0, 0);
    } catch(e) {
        /* security error, img on diff domain */
        return [{r: 0, g: 0, b: 0}, 0];
    }

    const data = imageData.data;
    const rgb = {r: 0, g: 0, b: 0};
    let count = 0; 

    for (let i = 0; i < data.length; i += 4) {
        ++count;
        rgb.r += data[i];
        rgb.g += data[i+1];
        rgb.b += data[i+2];
    }

    // ~~ used to floor values
    rgb.r = ~~(rgb.r/count);
    rgb.g = ~~(rgb.g/count);
    rgb.b = ~~(rgb.b/count);

    // https://tannerhelland.com/2011/10/01/grayscale-image-algorithm-vb6.html
    const grayscale = (rgb.r * 0.3 + rgb.g * 0.59 + rgb.b * 0.11)

    return [rgb, grayscale];
};

const ReferenceImage = (props) => {
    const { 
        photoInfo,
        loadNewImage,
        target, 
        setTarget,
        score,
    } = props;

    const [grayscale, setGrayscale] = React.useState(true);
    const [showSwatch, setShowSwatch] = React.useState(false);

    let imageWrapper;
    const updateTarget = evt => {
        // Bail if already at round end state.
        if (score != null) return;

        const elementCoord = evt.target.getBoundingClientRect();
        let xPos = evt.clientX - elementCoord.left;
        let yPos = evt.clientY - elementCoord.top;

        // Don't seem to need this if using bounding rect. 
        // const scrollableEl = document.scrollingElement;
        // xPos += scrollableEl.scrollLeft;
        // yPos += scrollableEl.scrollTop;

        const [color, grayscale] = getTargetColor(evt.target, xPos, yPos);

        setTarget({
            left: xPos + imageWrapper.offsetLeft - TARGET_MARKER_RADIUS,
            top: yPos + imageWrapper.offsetTop - TARGET_MARKER_RADIUS,
            color,
            grayscale,
        });
    }

    let swatchColor = null; 
    if (showSwatch && target) {
        swatchColor = toCssColor( grayscale ? target.grayscale : target.color);
    }

    let displayedContent = <div className="reference-image__loading-spinner">Loading...</div>;

    if (photoInfo) {
        displayedContent = (
            <React.Fragment>
                <div className="reference-image__image-container" ref={el => imageWrapper = el}>
                    <img 
                        className={classNames('reference-image__image', {
                            'reference-image__image--bw': grayscale, 
                        })}
                        alt={photoInfo.alt_description}
                        src={photoInfo.urls.regular} 
                        crossOrigin="anonymous"
                        onMouseDown={updateTarget}
                    />
                    <div className={classNames('target-marker', {
                            'target-marker--visible': !!target, 
                        })}
                        style={target && {left: target.left, top: target.top}}
                    >
                        <div 
                            className="target-marker__color" 
                            style={swatchColor ? {backgroundColor: swatchColor} : {}} 
                        />
                    </div>
                </div>
                <div className="reference-image__info">
                    {'Photo by '}
                    <a 
                        href={photoInfo.user.links.html} 
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {photoInfo.user.name}
                    </a>
                    {' on Unsplash.'}
                </div>
            </React.Fragment>
        )
    }

    return (
        <div className="reference-image">
            <div className="reference-image__actions">
                <button 
                    className="btn"
                    onClick={() => setGrayscale(!grayscale)}
                >
                    View in {grayscale ? 'color' : 'grayscale'}
                </button>
                <button 
                    className="btn"
                    onClick={() => setShowSwatch(!showSwatch)}
                >
                    {showSwatch ? 'Hide' : 'Show'} swatch
                </button>
                <button 
                    className="btn btn--right"
                    onClick={loadNewImage}>
                    New Image
                </button>
            </div>
            {/* <canvas className="testCanvas" width="50" height="50"></canvas> */}
            {displayedContent}
        </div>
    );
}

const mapStateToProps = state => ({
    target: state.target,
    score: state.score,
});

const mapDispatchToProps = {
    setTarget,
};

export default connect(
    mapStateToProps, 
    mapDispatchToProps,
)(ReferenceImage);