import React from 'react';

const Header = () => {

    return (
        <div className="app-header">
            VALU
        </div>
    )
}

export default Header;